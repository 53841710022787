<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('publication_management.subscriber_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset">
                        <b-row>
                            <b-col xs="12" sm="12" md="6" lg="6">
                                <ValidationProvider name="Fee type" vid="fee_type">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="region_office_id"
                                    >
                                    <template v-slot:label>
                                        {{$t('external_research.subscription_type')}}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.fee_type"
                                        :options="feeTypeList"
                                        id="fee_type"
                                    >
                                        <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="12" md="6" lg="6">
                                <ValidationProvider name="Year" vid="year_id">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="year_id"
                                    >
                                    <template v-slot:label>
                                        {{$t('external_research.for_year')}}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.year_no"
                                        :options="getForYearsList"
                                        id="year_id"
                                    >
                                        <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="12" md="6" lg="6" v-if="$store.state.Auth.activeRoleId === 1">
                                <ValidationProvider name="Region" vid="region_office_id">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="region_office_id"
                                    >
                                    <template v-slot:label>
                                        {{$t('warehouse_config.region')}}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.office_id"
                                        :options="regionNameList"
                                        id="region_office_id"
                                    >
                                        <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xl="6" lg="6" sm="12" xs="12">
                                <ValidationProvider name="From Date"  vid="from_date" rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="from_date"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('publication_management.from_date')}}
                                    </template>
                                    <b-form-input
                                        plain
                                        v-model="search.from_date"
                                        id="from_date"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                <ValidationProvider name="To Date" vid="to_date">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="to_date"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{ $t('publication_management.to_date') }}
                                        </template>
                                        <b-form-input
                                            id="to_date"
                                            plain
                                            v-model="search.to_date"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                            </div>
                        </b-row>
                    </b-form>
                </ValidationObserver>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12">
            <b-overlay :show="loadingData">
                <b-row v-if='reportData.length > 0'>
                    <b-col md="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <h4 class="card-title">{{ $t('publication_management.subscriber_report') }}  {{ $t('globalTrans.list') }}</h4>
                            </template>

                            <template v-slot:headerAction>
                                <b-button @click="pdfExport" class="btn_add_new">
                                    <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                                </b-button>
                            </template>

                            <template v-slot:body>
                                <b-overlay>
                                    <b-row mt-5>
                                        <b-col md="12" class="table-responsive">
                                            <div style="border: 2px solid;margin:10px;padding:10px">

                                                <!-- List Report Head Component -->
                                                <b-row>
                                                    <b-col>
                                                    <list-report-head :base-url="agriResearchServiceBaseUrl" uri="/report-heading/detail" :org-id=11>
                                                        <template v-slot:projectNameSlot>
                                                        </template>
                                                        <template v-slot:default>
                                                            {{ $t('publication_management.subscriber_report') }}
                                                        </template>
                                                    </list-report-head>
                                                    </b-col>
                                                </b-row>

                                                <!-- Search Parameters for Report -->
                                                <b-row>
                                                    <b-col>
                                                        <div class="text-black">
                                                            <b-row>
                                                                <b-col md="4">
                                                                    {{ $t('external_research.subscription_type') }}: <strong>{{ search.fee_type ? getColumnName(feeTypeList, search.fee_type) : $t('globalTrans.all') }}</strong>
                                                                </b-col>
                                                                <b-col md="4">
                                                                    {{ $t('external_research.for_year') }}: <strong>{{ search.year_no ? getColumnName(forYearsList, search.year_no) : $t('globalTrans.all') }}</strong>
                                                                </b-col>
                                                                <b-col md="4">
                                                                    {{ $t('warehouse_config.region') }}: <strong>{{ search.office_id ? getColumnName(regionNameList, search.office_id) : $t('globalTrans.all') }}</strong>
                                                                </b-col>
                                                                <b-col md="4">
                                                                    {{ $t('publication_management.from_date') }}:
                                                                    <strong>
                                                                        <slot v-if="search.from_date">
                                                                            {{ search.from_date | dateFormat }}
                                                                        </slot>
                                                                        <slot v-else>
                                                                            {{ $t('globalTrans.all') }}
                                                                        </slot>
                                                                    </strong>
                                                                </b-col>
                                                                <b-col md="4">
                                                                    {{ $t('publication_management.to_date') }}:
                                                                    <strong>
                                                                        <slot v-if="search.to_date">
                                                                            {{ search.to_date | dateFormat }}
                                                                        </slot>
                                                                        <slot v-else>
                                                                            {{ $t('globalTrans.all') }}
                                                                        </slot>
                                                                    </strong>
                                                                </b-col>
                                                            </b-row>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                    <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="reportData.length > 0">
                                                        <b-thead>
                                                             <b-tr>
                                                                <b-th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                                <b-th class="text-center" style="width:13%">{{ $t('org_pro.is_regional_office') }}</b-th>
                                                                <b-th class="text-center" style="width:10%">{{ $t('org_pro_district.district') }}</b-th>
                                                                <b-th class="text-center">{{ $t('publication_management.subscriber_id') }}</b-th>
                                                                <b-th class="text-center">{{ $t('publication_management.subscriber_info') }}</b-th>
                                                                <b-th class="text-center">{{ $t('external_research.subscription_info') }}</b-th>
                                                                <b-th class="text-center" style="width:10%">{{ $t('publication_management.fee_received') }}</b-th>
                                                            </b-tr>
                                                        </b-thead>
                                                        <template v-for="report in reportData">
                                                            <b-tr v-for="(data, index) in report.office" :key="index">
                                                                <b-td class="text-center">
                                                                    {{ $n(data.serial) }}
                                                                </b-td>
                                                                <slot v-if="index === 0">
                                                                    <b-td class="text-center" :rowspan="report.office.length">
                                                                    {{ getColumnName($store.state.commonObj.officeList, data.office_id)}}
                                                                    </b-td>
                                                                </slot>
                                                                <b-td class="text-center">{{ getColumnName($store.state.commonObj.districtList, data.district_id)}}</b-td>
                                                                <b-td class="text-center">{{ data.subscribe_auto_id }}</b-td>
                                                                <b-td class="text-left">
                                                                    <span>{{$t('publication_management.subscriber_name')}}: {{ (currentLocale === 'en') ? data.name : data.name_bn }}</span><br/>
                                                                    <span>{{$t('publication_management.subscriber_mobile_number')}}: {{ data.mobile_no | mobileNumber }}</span><br/>
                                                                    <span>{{$t('publication_management.mailing_address')}}: {{ data.mailing_address }}</span><br/>
                                                                </b-td>
                                                                <b-td class="text-left">
                                                                    <span>{{$t('external_research.subscription_type')}}: {{ getColumnName(feeTypeList, data.fee_type)}}</span><br/>
                                                                    <span>{{$t('external_research.number_of_subscription')}}: {{ $n(data.number_of_subscription) }}</span><br/>
                                                                    <span>{{ $t('publication_management.subscription_time_period') + ' (' + $t('globalTrans.year') +')' }}: {{ $n(data.year_no) }}</span><br/>
                                                                    <span>{{$t('globalTrans.subscription_start_date')}}: {{ data.start_date | dateFormat }}</span><br/>
                                                                    <span>{{$t('globalTrans.subscription_end_date')}}: {{ data.end_date | dateFormat }}</span>
                                                                </b-td>
                                                                <b-td class="text-right">{{ $n(data.pub_total_fee) }}</b-td>
                                                            </b-tr>
                                                            <b-tr :key="report.office_id">
                                                                <b-th colspan="6" class="text-right">{{ $t('sitePreference.total') }}</b-th>
                                                                <b-th class="text-right">{{ $n(report.total) }}</b-th>
                                                            </b-tr>
                                                        </template>
                                                        <b-tr key="total">
                                                            <b-th colspan="6" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                                                            <b-th class="text-right">{{ $n(total) }}</b-th>
                                                        </b-tr>
                                                    </b-table-simple>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-overlay>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <div v-else class="panel-body text-center mt-3">
                    <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                </div>
            </b-overlay>
          </b-col>
          <!-- <pre>{{ search }}</pre>
          <pre>{{ yearList }}</pre> -->
        </b-row>
    </b-container>
</template>
<script>

import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import flatpickr from 'flatpickr'
import { subscriberReportListApi } from '../../../api/routes'
import ExportPdf from './export_pdf_list'
export default {
    components: {
        ListReportHead
    },
    created () {
        this.regionNameList = this.getRegionNameList(11)
        if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.is_org_admin === 0) {
            this.search.office_id = this.$store.state.Auth.authUser.office_id
        }
    },
    mounted () {
        flatpickr('#from_date', {})
        flatpickr('#to_date', {})
    },
    data () {
        return {
            agriResearchServiceBaseUrl,
            search: {
                office_id: 0,
                fee_type: 0,
                year_no: 0,
                pub_type_id: 1,
                pub_title_id: 1,
                from_date: '',
                to_date: ''
            },
            params: {
                office_id: 0,
                fee_type: 0,
                year_no: 0,
                pub_type_id: 1,
                pub_title_id: 1,
                from_date: '',
                to_date: ''
            },
            regionNameList: [],
            reportData: [],
            forYearsList: [
                { value: 1, text_en: '1', text_bn: '১' },
                { value: 2, text_en: '2', text_bn: '২' },
                { value: 3, text_en: '3', text_bn: '৩' }
            ],
            pubTitleList: [],
            total: 0,
            loadingData: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
        }
    },
    computed: {
        getForYearsList: function () {
            return this.forYearsList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        feeTypeList () {
            return [
                { value: 1, text: this.$i18n.locale === 'en' ? '1-19' : '১-১৯', text_en: '1-19', text_bn: '১-১৯' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Above 19' : '১৯ এর উপরে', text_en: 'Above 19', text_bn: '১৯ এর উপরে' }
            ]
        },
        yearList: function () {
            var max = new Date().getFullYear()
            var min = max + 10
            var years = []
            for (var i = max; i <= min; i++) {
                const yearData = {}
                yearData.value = i
                yearData.text = this.$i18n.locale === 'bn' ? this.$n(i, { useGrouping: false }) : i
                yearData.text_en = i
                yearData.text_bn = i
                years.push(yearData)
            }
            return years
        },
        publicationTypeList: function () {
            return this.$store.state.AgriResearch.commonObj.publicationTypeList.filter(type => type.status === 1).map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    watch: {
        '$i18n.locale': function () {
            this.pubTitleList = this.getPublicationTitleList()
        }
    },
    methods: {
        getColumnName (list, groupId) {
            const obj = list.find(item => item.value === parseInt(groupId))
            if (typeof obj !== 'undefined') {
                if (this.$i18n.locale === 'bn') {
                return obj.text_bn
                }
                return obj.text_en
            } else {
                return ''
            }
        },
        getRegionNameList (orgId) {
            const tmpList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.org_id === orgId)
            return tmpList.map(item => ({ text: item.text, value: item.value, text_en: item.text_en, text_bn: item.text_bn }))
        },
        async loadData () {
            this.params.pub_type_id = this.search.pub_type_id
            this.params.pub_title_id = this.search.pub_title_id
            this.params.to_date = this.search.to_date
            this.params.from_date = this.search.from_date

            this.loadingData = true
            const result = await RestApi.getData(agriResearchServiceBaseUrl, subscriberReportListApi, this.search)
            if (result.success && result.data) {
                this.loadingData = false
                this.reportData = result.data
                this.total = result.total
            } else {
               this.reportData = []
               this.loadingData = false
            }
        },
         getPublicationTitleList () {
            return this.$store.state.AgriResearch.commonObj.publicationTitleList.filter(item => item.status === 1 && item.pub_type_id === this.search.pub_type_id)
        },
        getPubTypeName (id) {
            const pubTypeName = this.$store.state.AgriResearch.commonObj.publicationTypeList.find(type => type.value === id)
            if (this.$i18n.locale === 'bn') {
                return pubTypeName.text_bn
            } else {
                return pubTypeName.text_en
            }
        },
        getPubTitleName (id) {
            const pubTtileName = this.$store.state.AgriResearch.commonObj.publicationTitleList.find(type => type.value === id)
            if (this.$i18n.locale === 'bn') {
                return pubTtileName.text_bn
            } else {
                return pubTtileName.text_en
            }
        },
        pdfExport () {
          const reportTitle = this.$t('publication_management.subscriber_report')
          ExportPdf.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 11, reportTitle, this.reportData, this, this.search)
        }
    }
}
</script>
