import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-updated'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat, mobileNumber } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, reportData, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
          columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hh', alignment: 'center' }
      ]
      pdfContent.push({ text: '', style: 'fertilizer' })
      let fromDate = vm.$t('globalTrans.all')
      let toDate = vm.$t('globalTrans.all')
      if (search.from_date) {
        fromDate = dateFormat(search.from_date)
      }
      if (search.to_date) {
        toDate = dateFormat(search.to_date)
      }
      const allRowDs = [
        [
          { text: vm.$t('external_research.subscription_type') + ' : ' + (search.fee_type ? vm.getColumnName(vm.feeTypeList, search.fee_type) : vm.$t('globalTrans.all')), alignment: 'left', style: 'thh' },
          { text: vm.$t('external_research.for_year') + ' : ' + (search.year_no ? vm.getColumnName(vm.forYearsList, search.year_no) : vm.$t('globalTrans.all')), alignment: 'left', style: 'thh' },
          { text: vm.$t('warehouse_config.region') + ' : ' + (search.office_id ? vm.getColumnName(vm.regionNameList, search.office_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'thh' }
        ],
        [
          { text: vm.$t('publication_management.from_date') + ' : ' + fromDate, alignment: 'left', style: 'thh' },
          { text: vm.$t('publication_management.to_date') + ' : ' + toDate, alignment: 'left', style: 'thh' },
          {}
        ]
      ]
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: '*',
          body: allRowDs
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('org_pro.is_regional_office'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('org_pro_district.district'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('publication_management.subscriber_id'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('publication_management.subscriber_info'), style: 'th', alignment: 'left', bold: true },
          { text: vm.$t('external_research.subscription_info'), style: 'th', alignment: 'left', bold: true },
          { text: vm.$t('publication_management.fee_received'), style: 'th', alignment: 'right', bold: true }
        ]
      ]
      if (reportData.length > 0) {
        reportData.forEach(report => {
          report.office.forEach((data, index) => {
            const subsName = (i18n.locale === 'bn') ? data.name_bn : data.name
            const startDate = dateFormat(data.start_date)
            const endDate = dateFormat(data.end_date)
            const userInfo = vm.$t('publication_management.subscriber_name') + ':' + subsName + '\n' +
            vm.$t('publication_management.subscriber_mobile_number') + ':' + mobileNumber(data.mobile_no) + '\n' +
            vm.$t('publication_management.mailing_address') + ':' + data.mailing_address
            const subscriber = vm.$t('external_research.subscription_type') + ':' + vm.getColumnName(vm.feeTypeList, data.fee_type) + '\n' +
            vm.$t('external_research.number_of_subscription') + ':' + vm.$n(data.number_of_subscription) + '\n' +
            vm.$t('publication_management.subscription_time_period') + ' (' + vm.$t('globalTrans.year') + ')' + ':' + vm.$n(data.year_no) + '\n' +
            vm.$t('globalTrans.subscription_start_date') + ':' + startDate + '\n' +
            vm.$t('globalTrans.subscription_end_date') + ':' + endDate
            const newLoop = []
            newLoop.push({ text: vm.$n(data.serial, { useGrouping: false }), style: 'td', alignment: 'center' })
            if (index === 0) {
              newLoop.push({ text: vm.getColumnName(vm.$store.state.commonObj.officeList, data.office_id), style: 'td', rowSpan: report.office.length, alignment: 'center' })
            } else {
              newLoop.push({})
            }
            newLoop.push(
              { text: vm.getColumnName(vm.$store.state.commonObj.districtList, data.district_id), alignment: 'center', style: 'td' },
              { text: data.subscribe_auto_id, alignment: 'center', style: 'td' },
              { text: userInfo, alignment: 'left', style: 'td' },
              { text: subscriber, alignment: 'left', style: 'td' },
              { text: vm.$n(data.pub_total_fee), alignment: 'right', style: 'td' }
            )
            allRows.push(newLoop)
          })
          allRows.push([
            { text: vm.$t('sitePreference.total'), style: 'th', alignment: 'right', bold: true, colSpan: 6 },
            {},
            {},
            {},
            {},
            {},
            { text: vm.$n(report.total), alignment: 'right', style: 'th' }
          ])
        })
      }
      allRows.push([
        { text: vm.$t('globalTrans.total'), style: 'th', alignment: 'right', bold: true, colSpan: 6 },
        {},
        {},
        {},
        {},
        {},
        { text: vm.$n(vm.total), alignment: 'right', style: 'th' }
      ])
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['7%', '15%', '12%', '10%', '20%', '23%', '10%'],
          body: allRows
        }
      })
      pdfContent.push({ text: '', style: 'fertilizer' })
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
        },
        org: {
            fontSize: 9,
            bold: true,
            margin: [5, -25, 5, 5]
        },
        address: {
            fontSize: 11,
            margin: [0, 0, 0, 5]
        },
        hh: {
            fontSize: 13,
            bold: true,
            margin: [10, 10, 25, 20]
        },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 0]
        },
      //   address: {
      //     fontSize: 9,
      //     margin: [0, -10, 0, 0]
      //   },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).download('subscriber-report')
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
